
import { Component, Vue } from 'vue-property-decorator'
import { KeepAlive } from '@/utils/decorators'
import ValveOpenDialog from './ValveOpenDialog.vue'

@Component({
  components: { ValveOpenDialog }
})
@KeepAlive
export default class Department extends Vue {
  private info = {
    buildingName: '',
    buildingId: '',
    valveList: [] as any
  }

  private loading = false
  private loadingText = '电磁阀开启中'

  private activeName ='0'
  private isShowDeviceOpen = false
  private deviceNumber = false
  private index = null as any

  private menuObj = {
    task: false,
    record: false
  }

  get buildingId () {
    return this.$route.query.buildingId as string
  }

  created () {
    // console.log('create')
    this.getDetail()
    this.getMenu()
  }

  getMenu () {
    const list = sessionStorage.getItem('childNode') || ''
    if (list) {
      const menu = JSON.parse(list)?.childNode
      this.menuObj.task = menu.some((item: any) => {
        return item.url === '/smartHouse/task'
      })
      this.menuObj.record = menu.some((item: any) => {
        return item.url === '/smartHouse/record'
      })
    }
  }

  getDetail (refresh?: any, deviceNumber?: any) {
    // console.log('getDetail')
    // refresh：否关闭loading，点击弹窗内的取消不用传此参数
    this.$axios.get(this.$apis.smartHouse.selectSqIrrigationValveBuildingById, {
      buildingId: this.buildingId
    }).then((res) => {
      this.info = res || []
      // if (refresh) {
      //   const target = res?.valveList?.find((item: any) => {
      //     return item.deviceNumber === deviceNumber
      //   })
      //   console.log('target', target)
      //   if (target?.switchStatus === '1') {
      //     this.$message.success('操作成功')
      //   } else {
      //     this.$message.warning('操作失败请重试')
      //   }
      // }
    })
  }

  changeStatus (deviceNumber: any, command: any, index: any) {
    // console.log('index', index)
    this.index = index
    // console.log('关闭', deviceNumber, command)
    if (command === '0') {
      this.deviceNumber = deviceNumber
      this.isShowDeviceOpen = true
    } else {
      this.loadingText = '电磁阀关闭中'
      this.loading = true
      this.$axios.post(this.$apis.smartHouse.insertSqIrrigationValveRecord, {
        deviceNumber,
        commandSource: '1',
        command: '0'
      }).then(() => {
        setTimeout(() => {
          // this.getDetail('refresh', deviceNumber)
          this.getSingleDevice(deviceNumber)
        }, 5000)
      })
      // }, 5000)
    }
  }

  getSingleDevice (deviceNumber: any) {
    this.$axios.get(this.$apis.smartHouse.selectSqIrrigationValveByNumber, {
      deviceNumber
    }).then((res: any) => {
      // console.log('re2', res)
      this.info.valveList.splice(this.index, 1, res)
    }).finally(() => {
      this.loading = false
    })
  }

  updateDetail (deviceNumber: any) {
    // console.log('updateDetail')
    this.loadingText = '电磁阀开启中'
    this.loading = true
    setTimeout(() => {
      this.getSingleDevice(deviceNumber)
      // this.$axios.get(this.$apis.smartHouse.selectSqIrrigationValveBuildingById, {
      //   buildingId: this.buildingId
      // }).then((res) => {
      //   this.loading = false
      //   console.log('参数', deviceNumber, res)
      //   const target = res.valveList.find((item: any) => {
      //     return item.deviceNumber === deviceNumber
      //   })
      //   if (target?.switchStatus === '1') {
      //     this.$message.success('开启成功')
      //   } else {
      //     this.$message.warning('开启失败请重试')
      //   }
      //   console.log('target', target)
      //   this.info = res || []
      // })
    }, 5000)
  }

  onSearch (value: any) {
    console.log('value', value)
    if (value === '1') {
      this.$router.push({
        path: '/smartHouse/realtime/task',
        query: {
          buildingId: this.info.buildingId
        }
      })
    } else if (value === '2') {
      this.$router.push({
        path: '/smartHouse/realtime/record',
        query: {
          buildingId: this.info.buildingId
        }
      })
    }
  }
}
