
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'

@Component
export default class ValveOpenDialog extends Vue {
  @Prop() private isShow!: boolean;
  @Prop() private deviceNumber!: string;

  private loading = false
  private info = {
    duration: ''
  }

  private options = [{
    value: '5',
    label: '5分钟'
  }, {
    value: '10',
    label: '10分钟'
  }, {
    value: '15',
    label: '15分钟'
  }, {
    value: '20',
    label: '20分钟'
  }]

  private isSubmit = false
  private rules = {
    duration: [
      { required: true, message: '请选择或输入合理开启时间', trigger: 'change' }
    ]
  }

  onsubmit () {
    (this.$refs.formData as ElForm).validate(valid => {
      // console.log('info.duration', this.info.duration)
      if (valid) {
        this.isSubmit = true
        this.loading = true
        this.info.duration = parseFloat(this.info.duration).toString()
        // console.log(this.info.duration)
        this.$axios.post(this.$apis.smartHouse.insertSqIrrigationValveRecord, {
          deviceNumber: this.deviceNumber,
          execLength: this.info.duration,
          commandSource: '1',
          command: '1'
        }).then(() => {
          this.$emit('valveStatusUpdate', this.deviceNumber)
          this.$emit('update:isShow', false)
        }).finally(() => {
          this.isSubmit = false
        })
      }
    })
  }

  selectChange () {
    if (!/^[1-9][0-9]*$/.test(this.info.duration.toString()) || Number(this.info.duration) < 0 || Number(this.info.duration) > 20) {
      this.info.duration = ''
    } else {
      this.info.duration = this.info.duration + '分钟'
    }
  }

  closeDialog () {
    // console.log('closeDialog')
    this.info.duration = '';
    (this.$refs.formData as ElForm).resetFields()
    this.$emit('update:isShow', false)
    // this.$emit('onCancel')
  }
}
